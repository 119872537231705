import React from "react";
import "./App.css";

const App = () => {

  return (
    <div className="App">
      <div className="centered-component">
        <div className="component-1">
          <div className="component-1-1">
            <h1>Bull or Pull?</h1>
            <h4>BOP token 0X00</h4>
            <h4>Next dice roll is in x days</h4>
            <hr></hr>
          </div>
          <div className="whitepaper">
            <p>
              Bull or Pull Token (BOP) introduces a bold and innovative risk-driven tokenomics model built on the Solana blockchain. 
              Merging high-stakes decision-making and blockchain technology, BOP is designed for thrill-seekers in the cryptocurrency 
              community. The concept revolves around the daily dynamic of either increasing the token's scarcity through burns or 
              orchestrating a rug pull event, resetting the ecosystem and creating opportunities for new participants. The cyclical nature 
              of BOP ensures its appeal as both a high-risk investment and a novel entertainment experience, continually resetting and 
              growing with each iteration.
            </p>

            <h2>Core Principles</h2>
            <ul>
              <li><strong>Risk Dynamics:</strong> BOP introduces a unique risk element where token holders face daily uncertainty between token burns and rug pulls.</li>
              <li><strong>Transparency:</strong> Leveraging the Solana blockchain, all transactions, wallet movements, and tokenomics events are publicly verifiable.</li>
              <li><strong>Sustainability:</strong> Each rug pull reinvests 90% of proceeds into the next iteration of the token, ensuring ongoing liquidity and attracting new participants.</li>
            </ul>

            <h2>Mechanics</h2>
            <ul>
              <li><strong>Initial Liquidity Pool (LP):</strong> Upon launch, a small percentage of the total supply (25-33%) is added to the LP.</li>
              <li><strong>Developer Wallet:</strong> The remaining 67-75% of the supply is withheld in the dev wallet to facilitate burns and incentivize the ecosystem.</li>
              <li><strong>Redistribution of Proceeds:</strong></li>
              <ul>
                <li>90%: Reinvested into the liquidity pool of the next token iteration (e.g., BOP1 → BOP2).</li>
                <li>10%: Retained by the development team to sustain operations and promote the ecosystem.</li>
              </ul>
              <li><strong>New Token Launch:</strong> A new token with identical mechanics is created, ensuring continuity and maintaining community engagement.</li>
              <li><strong>Growing Ecosystem:</strong> Each successive iteration benefits from a larger starting liquidity pool, potentially attracting more participants.</li>
              <li><strong>Ecosystem Sustainability:</strong> The reinvestment of 90% of rug pull proceeds into subsequent tokens ensures the long-term viability of the ecosystem, with each iteration building on the last.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>
              Bull or Pull Token (BOP) introduces a high-stakes, gamified approach to cryptocurrency investing, blending the thrill of calculated risk with deflationary tokenomics. Built on the Solana blockchain, BOP offers transparency, scalability, and a sustainable ecosystem fueled by continuous iterations. For risk-takers and thrill-seekers, BOP represents a novel frontier in blockchain innovation.
            </p>
            <p><strong>Join the game. Embrace the risk. Will it be Bull or Pull?</strong></p>
          </div>
        </div>
        <div className="component-2"></div>
      </div>
    </div>
  );
};

export default App;